'use client'

import { useTranslations } from 'next-intl'
import { useMemo, useState } from 'react'
import { useController } from 'react-hook-form'
import styled, { css } from 'styled-components'

import {
  Box,
  ContentText,
  InputField,
  ListItems,
  px2rem,
  spacing,
  useOutsideClickClose,
} from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'
import { useTheme } from '@/shared/hooks/useTheme'

import type {
  EnterpriseUsageOptions,
  FormFiledValues,
  HousingOptions,
  ListItemsType,
  QuickSearchForm,
} from '../types'
import { ENTERPRISE_USAGE_LIST, HOUSING_LIST } from '../utils'

type HousingSelectProps = FormFiledValues & {
  texts: {
    topText?: string
    label: string
    errorMessage: string
  }
  handleSelect: (
    name: keyof QuickSearchForm,
    label: HousingOptions | EnterpriseUsageOptions,
  ) => void
  isEnterprise?: boolean
}

export const HousingSelect = ({
  texts: { topText, ...inputTexts },
  handleSelect,
  isEnterprise,
  ...formConfig
}: HousingSelectProps) => {
  const [isOpened, setIsOpened] = useState(false)
  const [isFirstOpen, setIsFirstOpen] = useState(true)
  const t = useTranslations('quickSearchForm.selectLabels')

  const { wrapperRef } = useOutsideClickClose(() => {
    setIsFirstOpen(false)
    setIsOpened(false)
  }, !isOpened)
  const { colors } = useTheme()

  const {
    field: { ref: fieldRef, ...fieldProps },
    fieldState: { error },
  } = useController({ ...formConfig, defaultValue: '' })

  const housingOptions: ListItemsType[] = useMemo(
    () => {
      return (Object.keys(HOUSING_LIST) as HousingOptions[]).map((key) => ({
        name: t(key),
        value: key,
        icon: <Icon size={48} icon={HOUSING_LIST[key].icon} />,
      }))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const usageList: ListItemsType[] = useMemo(
    () => {
      return (Object.keys(ENTERPRISE_USAGE_LIST) as EnterpriseUsageOptions[]).map((key) => ({
        name: t(key),
        value: key,
        icon: <Icon size={48} icon={ENTERPRISE_USAGE_LIST[key].icon} />,
      }))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const handleClose = () => {
    setIsFirstOpen(false)
    setIsOpened(false)
  }

  const handleOnSelect = (value: HousingOptions | EnterpriseUsageOptions) => {
    setIsFirstOpen(false)
    setIsOpened(false)
    handleSelect(fieldProps.name, value)
  }

  const handleFocus = () => {
    setIsOpened(true)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={spacing.xxxxs}
      flex="1"
      position="relative"
      boxRef={wrapperRef}
    >
      <ContentText color={colors.textSecondary}>{topText}</ContentText>
      <StyledInput
        {...fieldProps}
        {...inputTexts}
        value={fieldProps.value ? t(fieldProps.value as HousingOptions) : undefined}
        inputRef={fieldRef}
        error={Boolean(error) && !isFirstOpen}
        readOnly
        aria-haspopup="listbox"
        onClick={() => {
          setIsOpened(true)
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault()
            setIsOpened(true)
          }
        }}
        onFocus={handleFocus}
        $isDropdownOpened={isOpened}
        data-testid="quick-search-form-housing-input"
      />
      <Box width="100%" position="absolute" bottom="-0.5rem">
        <ListItems
          items={isEnterprise ? usageList : housingOptions}
          opened={isOpened}
          onClose={handleClose}
          onSelect={handleOnSelect}
          role="menu"
          listItemRole="menuitem"
          activateFirstItem
          disableBorders
          borderRadius="0.25rem !important"
          boxShadow={`0 0 ${px2rem(27)} ${px2rem(8)} rgba(0, 0, 0, 0.2)`}
          width={isEnterprise ? 'fit-content' : px2rem(288)}
          bottom={0}
          left="50%"
          transform="translate(-50%)"
          data-testid="quick-search-form-housing-select"
        />
      </Box>
    </Box>
  )
}

const StyledInput = styled(InputField)<{ $isDropdownOpened?: boolean; error?: boolean }>`
  :read-only {
    cursor: pointer;
    border-color: ${({ theme, error }) =>
      error ? theme.colors.brandAlert : theme.colors.borderPrimary};

    ${({ $isDropdownOpened, theme }) =>
      $isDropdownOpened
        ? css`
            border-color: ${theme.colors.brandPrimary} !important;

            & + label {
              color: ${theme.colors.brandPrimary} !important;
            }
          `
        : css`
            & + label {
              font-size: 1.25rem;
              transform: none;
            }
          `}
  }

  input {
    border-radius: ${spacing.xxxxs};
  }

  :read-only:hover {
    border-color: ${({ theme, error }) =>
      error ? theme.colors.brandAlert : theme.colors.brandPrimary};
  }
`
