/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo, useState } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { isValid as isSwedishPostalCode } from 'swedish-postal-code-validator'

import { useToggle } from '@fortum/elemental-ui'

import type {
  EnterpriseUsageOptions,
  HousingOptions,
} from '@/open-web/components/QuickSearch/types'
import { useGlobalStore } from '@/shared/store/provider'

export const useAddressAdditionalForm = <T extends FieldValues>() => {
  const [postalCode, setPostalCode] = useState<string>()
  const [streetAddress, setStreetAddress] = useState<string>()
  const [isAdditionalFormVisible, setIsAdditionalFormVisible] = useState(false)
  const [isPriceAreaError, setIsPriceAreaError] = useState(false)
  const [place, setPlace] = useState<string>()
  const [shouldShowModal, toggleShouldShowModal] = useToggle()
  const [isWaitingForValidation, setIsWaitingForValidation] = useState(false)

  const {
    postalCode: priceAreaPostalCode,
    housingSize,
    housingType,
    usageOption,
    estimatedConsumption,
    deliveryArea,
    isMultipleAreas,
    setUsageOption,
    setEstimatedConsumption,
    setHousingSize,
    setHousingType,
    setDeliveryArea,
    setPostalCode: setPriceAreaPostalCode,
    requestDeliveryArea,
    _isLoading,
  } = useGlobalStore((state) => state.housingInfo)

  const form = useForm<T>({
    mode: 'onTouched',
    values: {
      postalCode: postalCode || '',
      streetAddress: streetAddress || '',
      city: place || '',
      housingSize: housingSize ? String(housingSize) : '',
      housingType,
      usageOption,
      estimatedConsumption: estimatedConsumption ? String(estimatedConsumption) : '',
    } as unknown as T,
  })

  const resetInnerState = () => {
    setPlace(undefined)
    setStreetAddress(undefined)
    setIsAdditionalFormVisible(false)
    setIsPriceAreaError(false)
  }

  const onChange = (name: keyof T, value: string) => {
    switch (name) {
      case 'postalCode':
        // Only allow numbers
        if (!/^\d*$/.test(value)) {
          return
        }

        setPostalCode(value)
        resetInnerState()
        if (priceAreaPostalCode) {
          setPriceAreaPostalCode(undefined)
        }
        if (deliveryArea) {
          setDeliveryArea(undefined)
        }
        break

      case 'housingSize':
        setHousingSize(+value || undefined)
        break

      case 'housingType':
        setHousingType(value as HousingOptions)
        break

      case 'usageOption':
        setUsageOption(value as EnterpriseUsageOptions, true)
        if (value === 'large') {
          toggleShouldShowModal()
        }
        break

      case 'estimatedConsumption':
        setEstimatedConsumption(+value || undefined)
        break

      case 'city':
        setPlace(value)
        if (deliveryArea) {
          setDeliveryArea(undefined)
        }
        break

      case 'streetAddress':
        setStreetAddress(value)
        if (deliveryArea) {
          setDeliveryArea(undefined)
        }
        break
    }
  }

  const canRevalidateDeliveryArea =
    !Boolean(form.formState.errors.postalCode) &&
    !deliveryArea &&
    postalCode &&
    postalCode.length === 5 &&
    ((!streetAddress && !place) || (streetAddress && place))

  //Debounce on postal code input
  useEffect(() => {
    if (canRevalidateDeliveryArea) {
      setIsWaitingForValidation(true)
    }

    const timeout = setTimeout(() => {
      if (canRevalidateDeliveryArea) {
        setIsPriceAreaError(false) //reset error before refetch
        requestDeliveryArea(postalCode, streetAddress, place).catch(() => {
          setIsPriceAreaError(true)
        })

        setIsWaitingForValidation(false)
      }
    }, 1500)

    return () => clearTimeout(timeout)
  }, [postalCode, streetAddress, place, requestDeliveryArea])

  //Reset local state on postal code change
  useEffect(() => {
    if (priceAreaPostalCode) {
      setPostalCode(priceAreaPostalCode)
      resetInnerState()
    }
  }, [priceAreaPostalCode])

  //Trigger additional form on indecisive price area fetch
  useEffect(() => {
    if (isMultipleAreas) {
      setIsAdditionalFormVisible(isMultipleAreas)
      setDeliveryArea(undefined)
    }
  }, [isMultipleAreas])

  const handleModalClose = () => {
    setUsageOption('small', true)
    toggleShouldShowModal()
  }

  const isPostalCodeWithoutErrors = useMemo(
    () =>
      postalCode &&
      isSwedishPostalCode(postalCode) &&
      Object.keys(form.formState.errors).length === 0 &&
      !_isLoading &&
      !isWaitingForValidation &&
      !isMultipleAreas &&
      !isPriceAreaError &&
      !isAdditionalFormVisible,
    [
      postalCode,
      form.formState.errors,
      _isLoading,
      isMultipleAreas,
      isPriceAreaError,
      isAdditionalFormVisible,
    ],
  )

  const isMultipleAreasValid = useMemo(
    () =>
      !_isLoading &&
      !isWaitingForValidation &&
      isAdditionalFormVisible &&
      !isPriceAreaError &&
      Object.keys(form.formState.errors).length === 0 &&
      Boolean(streetAddress) &&
      Boolean(place) &&
      Boolean(postalCode),
    [
      _isLoading,
      isWaitingForValidation,
      isPriceAreaError,
      postalCode,
      isMultipleAreas,
      form.formState.errors,
      streetAddress,
      place,
      isAdditionalFormVisible,
    ],
  )

  const isFormValid = useMemo(
    () => isPostalCodeWithoutErrors || isMultipleAreasValid,
    [isPostalCodeWithoutErrors, isMultipleAreasValid],
  )

  return {
    isFormValid,
    form,
    onChange,
    postalCode,
    streetAddress,
    place,
    isAdditionalFormVisible,
    isPriceAreaError,
    shouldShowModal,
    setPostalCode,
    setStreetAddress,
    setPlace,
    setIsAdditionalFormVisible,
    resetInnerState,
    toggleShouldShowModal,
    handleModalClose,
  }
}
