'use client'

import styled from 'styled-components'

import { Box, ContentText, spacing } from '@fortum/elemental-ui'

import type { BadgeEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'
import * as styleMixins from '@/shared/style/styleMixins'

import type { IconId } from '../../../Icon'
import { Icon } from '../../../Icon'

export type CardBadgeProps = Omit<BadgeEntry, 'sys' | '__typename'> & {
  /**
   * @textColor Badge text and icon color
   * @backgroundColor  Badge background color. By default provided color will be used with 0.2 opactity and as a paremeter of
   * `linear-gradient` function. eg. `linear-gradient(0deg, rgba(24, 210, 112, 0.20) 0%, rgba(24, 210, 112, 0.20) 100%), #FFF;`
   */
  colors: ColorProps<'textColor' | 'backgroundColor', never>
  /**
   * CSS transform function
   */
  transform?: string
}

/**
 * Simple badge component with text and icon.
 */
export const CardBadge = ({ title, colors, transform, icon }: CardBadgeProps) => {
  const theme = useTheme()
  const textColor = colors?.textColor || theme.colors.textAccent
  const backgroundColor = colors?.backgroundColor || theme.colors.backgroundPositive

  return (
    <Box
      display="flex"
      ph={{ default: spacing.xxxs, s: spacing.xxs }}
      pv={spacing.xxxxs}
      gap={spacing.xxxs}
      justifyContent="center"
      alignItems="center"
      borderRadius="4px"
      position="absolute"
      maxWidth="100%"
      transform={transform}
      zIndex="4"
      bottom="-100%"
      backgroundColor={backgroundColor}
      data-testid="card-badge"
    >
      {icon && <Icon icon={icon as IconId} color={textColor} size={24} />}
      {title && (
        <Text size="s" color={textColor} title={title}>
          {title}
        </Text>
      )}
    </Box>
  )
}

const Text = styled(ContentText)`
  ${styleMixins.truncate}
`
