'use client'

import { useTranslations } from 'next-intl'
import { useEffect, useState } from 'react'

import { Box, ButtonPill, IconArrowRight, spacing } from '@fortum/elemental-ui'

import { AdditionalInfoForm } from '@/shared/components/AdditionalInfoForm'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useAddressAdditionalForm } from '@/shared/hooks/useAddressAdditionalForm'
import { Link, useRouter } from '@/shared/navigation'
import { useGlobalStore } from '@/shared/store/provider'
import { stepEvent } from '@/shared/utils/sendGTMEvent'

import { Consumption } from './Consumption'
import { HousingSelect } from './HousingSelect'
import { Input } from './Input'
import { QuickSearchWrapper } from './QuickSearchWrapper'
import type { QuickSearchForm, QuickSearchProps } from './types'
import { QUICK_SEARCH_CONFIG } from './utils'

export const QuickSearchPrivate: React.FC<QuickSearchProps> = ({
  layout = 'responsive',
  primaryLink,
}) => {
  const [isConsumptionInputManual, setIsConsumptionInputManual] = useState(false)
  const l = useTranslations('quickSearchForm.labels')
  const router = useRouter()
  const flexDirection = layout === 'vertical' ? 'column' : { default: 'column', l: 'row' }

  const {
    housingSize,
    housingType,
    estimatedConsumption,
    deliveryArea,
    _isLoading,
    usageOption,
    resetHousingInfo,
  } = useGlobalStore((state) => state.housingInfo)

  const shouldDisplayConsumptionRow =
    estimatedConsumption || primaryLink || isConsumptionInputManual

  const { form, onChange, isPriceAreaError, isAdditionalFormVisible } =
    useAddressAdditionalForm<QuickSearchForm>()

  //Reset state if coming from Enterprise site
  useEffect(
    () => {
      if (usageOption) {
        resetHousingInfo()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usageOption],
  )

  return (
    <QuickSearchWrapper form={form}>
      <Box
        display="flex"
        flexDirection={flexDirection}
        flexWrap="wrap"
        gap={{ default: spacing.xxs, s: spacing.xs, l: spacing.xxs }}
      >
        <Input
          {...QUICK_SEARCH_CONFIG.postalCode}
          texts={{
            topText: l('postalCode.topText'),
            label: l('postalCode.label'),
            errorMessage: l('postalCode.errorMessage'),
          }}
          onChange={onChange}
          isLoading={_isLoading}
          isError={isPriceAreaError}
          isSuccess={Boolean(deliveryArea)}
        />
        {isAdditionalFormVisible && (
          <AdditionalInfoForm
            onChange={onChange}
            city={QUICK_SEARCH_CONFIG.city}
            streetAddress={QUICK_SEARCH_CONFIG.streetAddress}
          />
        )}
        <HousingSelect
          {...QUICK_SEARCH_CONFIG.housingType}
          handleSelect={onChange}
          texts={{
            topText: l('housingType.topText'),
            label: l('housingType.label'),
            errorMessage: l('housingType.errorMessage'),
          }}
        />
        <Input
          {...QUICK_SEARCH_CONFIG.housingSize}
          onChange={onChange}
          texts={{
            topText: l('housingSize.topText'),
            label: l('housingSize.label'),
            errorMessage: l('housingSize.errorMessage'),
          }}
        />
      </Box>
      {shouldDisplayConsumptionRow && (
        <Box
          display="flex"
          flexDirection={flexDirection}
          gap={{ default: spacing.xxs, s: spacing.xs, l: spacing.xxs }}
        >
          {housingType && (
            <Consumption
              isConsumptionInputManual={isConsumptionInputManual}
              setIsConsumptionInputManual={setIsConsumptionInputManual}
              onChange={onChange}
            />
          )}
          {primaryLink && (
            <Box
              display="flex"
              flexDirection={{ default: 'column', l: 'row' }}
              flex="2"
              alignItems={{ default: 'center', l: 'flex-end' }}
              justifyContent="flex-end"
            >
              <ButtonPill
                tag={Link}
                onClick={(event) => {
                  event.preventDefault()
                  router.push(getLinkEntryUrl(primaryLink))
                  stepEvent('find_contract', {
                    housing_type: housingType,
                    size: housingSize,
                    est_consumption: estimatedConsumption,
                  })
                }}
                data-testid="find-contracts-button"
                rightIcon={<IconArrowRight size={24} />}
              >
                {primaryLink.label}
              </ButtonPill>
            </Box>
          )}
        </Box>
      )}
    </QuickSearchWrapper>
  )
}
